import axios from 'axios';
import { parseCookies } from 'nookies';
import { AUTH_TOKEN_NAME } from '../lib/constants';
import { addAuthInterceptor } from './interceptors/authInterceptor';
import { addCSRFInterceptor } from './interceptors/csrfInteceptor';
import { addLocaleInterceptor } from './interceptors/localeInterceptor';
import { addPersonaInterceptor } from './interceptors/personaInterceptor';
import { addTokenInterceptor } from './interceptors/tokeninterceptor';
export function createApi() {
  var ctx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  var cookies = parseCookies(ctx);
  return axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    withCredentials: true,
    headers: {
      Authorization: "Bearer ".concat(cookies[AUTH_TOKEN_NAME]),
      'Content-Type': 'application/json',
      accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  });
}
export function setupApiClient() {
  var ctx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  var apiInstance = createApi(ctx);
  addTokenInterceptor(apiInstance);
  addPersonaInterceptor(apiInstance);
  addLocaleInterceptor(apiInstance);
  addCSRFInterceptor(apiInstance);
  addAuthInterceptor(apiInstance, ctx);
  return apiInstance;
}
export var api = setupApiClient();