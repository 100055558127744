// NEXT
export var NEXT_LOCALE = 'NEXT_LOCALE'; // LOCAL && SESSION STORAGES

export var SESSION_ANSWER_KEY = '_currentAnswers';
export var LOCAL_SURVEY_KEY = '_currentSurvey'; // COOKIES && HEADERS

export var COOKIE_BASE_NAME = process.env.NEXT_PUBLIC_COOKIE_BASE_NAME || '_m';
export var AUTH_TOKEN_NAME = "".concat(COOKIE_BASE_NAME, ".token");
export var AUTH_CALLBACK_URL = "".concat(COOKIE_BASE_NAME, ".callback-url");
export var AUTH_CSRF_TOKEN = "".concat(COOKIE_BASE_NAME, ".csrf-token");
export var AUTH_REFRESH_TOKEN_NAME = "".concat(COOKIE_BASE_NAME, ".refreshToken");
export var PERSONA_TOKEN_COOKIE = "".concat(COOKIE_BASE_NAME, ".persona");
export var PERSONA_TOKEN_HEADER = 'persona-token';
export var COOKIES_DOMAIN = process.env.NEXT_PUBLIC_COOKIES_DOMAIN; // MAGIC NUMBERS

export var THIRTY_DAYS_IN_SECONDS = 60 * 60 * 24 * 30;
export var ONE_DAY_IN_SECONDS = 60 * 60 * 24;
export var ONE_HOUR_IN_SECONDS = 60 * 60;
export var ONE_HOUR_IN_MILESECONDS = 1000 * 60 * 60;
export var ONE_MINUTE_IN_MILLISECONDS = 1000 * 60;
export var ONE_DAY_IN_MILESECONDS = 1000 * 60 * 60 * 24;
export var FIVE_MINUTE_IN_MILLISECONDS = 1000 * 60 * 5;
export var THIRTY_SECONDS_IN_MILLISECONDS = 1000 * 30;
export var FIFTEEN_MINUTES_IN_SECONDS = 60 * 15;
export var FIVE_DAYS_IN_SECONDS = 60 * 60 * 24 * 5;
export var FIVE_YEARS_IN_SECONDS = 60 * 60 * 24 * 365 * 5;
export var ONE_YEARS_IN_SECONDS = 60 * 60 * 24 * 365;
export var FILE_SIZE_ONE_MB = 1024 * 1024; // ERRORS

export var ERROR_CODE_TOKEN_EXPIRED = 'token.expired';
export var ERROR_CODE_PERSONA_NOT_FOUND = 'persona.notFound';
export var ERROR_CODE_INVALID_CREDENTIALS = 'invalid.credentials';
export var ERROR_CODE_CSRF_INVALID = 'csrf.tokenMismatch'; // BROADCAST

export var AUTH_BROADCAST_CHANNEL = 'auth';
export var BROADCAST_SIGNOUT_MESSAGE = 'signOut'; // URLS

export var GLOBAL_URL = 'https://www.globalmindpool.org';
export var UNDP_URL = 'https://www.undp.org';
export var MINDPOOL_WHITE_PAPER = 'https://mindpool-cdn.s3.us-east-2.amazonaws.com/assets/pdfs/mindpool-white-paper.pdf';
export var GDS_PLACEHOLDER_URL = 'https://datastudio.google.com/embed/reporting/2692b5e2-11a5-41f2-80bd-1ec4454aadd6/page/p_60sxsfg1mc';