import { destroyCookie, setCookie } from 'nookies';
import { AUTH_BROADCAST_CHANNEL, AUTH_REFRESH_TOKEN_NAME, AUTH_TOKEN_NAME, BROADCAST_SIGNOUT_MESSAGE, FIFTEEN_MINUTES_IN_SECONDS, FIVE_DAYS_IN_SECONDS } from '../../lib/constants';
var authChannel;
export var getAuthChannel = function getAuthChannel() {
  if (!authChannel) {
    if (typeof BroadcastChannel != 'undefined') {
      authChannel = new BroadcastChannel(AUTH_BROADCAST_CHANNEL);
    }
  }

  return authChannel;
};
export var clearAuthChannel = function clearAuthChannel() {
  if (authChannel) {
    authChannel.close();
    authChannel = null;
  }
};
export function clearAuthCookies() {
  var _authChannel;

  var ctx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  destroyCookie(ctx, AUTH_TOKEN_NAME, {
    path: '/',
    domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN
  });
  destroyCookie(ctx, AUTH_REFRESH_TOKEN_NAME, {
    path: '/',
    domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN
  });
  (_authChannel = authChannel) === null || _authChannel === void 0 ? void 0 : _authChannel.postMessage(BROADCAST_SIGNOUT_MESSAGE);
}
export function setAuthCookies(token, refreshToken) {
  var ctx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
  setCookie(ctx, AUTH_TOKEN_NAME, token, {
    maxAge: FIFTEEN_MINUTES_IN_SECONDS,
    path: '/',
    safe: true,
    domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN
  });
  setCookie(ctx, AUTH_REFRESH_TOKEN_NAME, refreshToken, {
    maxAge: FIVE_DAYS_IN_SECONDS,
    path: '/',
    safe: true,
    domain: process.env.NEXT_PUBLIC_AUTH_COOKIE_DOMAIN
  });
}