import { sourceLocale } from '../../lingui.config'; // LOCALE

export var DEFAULT_LOCALE = sourceLocale;
export var NEXT_LOCALE = 'NEXT_LOCALE'; // URL

export var UNDP_SITE_URL = 'https://www.undp.org';
export var MINDPOOL_SITE_URL = 'https://www.mindpool.com';
export var GLOBAL_MINDPOOL_LANDING_PAGE_URL = 'https://mindpool.com/solution/global-mindpool';
export var UNAUTHENTICATED_URL_REDIRECT = '/';
export var AUTHENTICATED_URL_REDIRECT = '/';
export var PROFILE_URL_REDIRECT = '/survey/end';
export var LOGIN_URL_REDIRECT = '/login';
var NEXT_PUBLIC_CLIMATE_URL = process.env.NEXT_PUBLIC_CLIMATE_URL;
export { NEXT_PUBLIC_CLIMATE_URL };
export var REST_COUNTRIES_API = '/';
export var DEMOGRAPHICS_SURVEY_SLUG = 'demographics';
export var RESOURCES_PATH = '/resources';
export var PRESS_PATH = '/press'; // COOKIES && HEADERS

export var COOKIE_BASE_NAME = process.env.NEXT_PUBLIC_COOKIE_BASE_NAME || '_m';
export var AUTH_TOKEN_NANE = "".concat(COOKIE_BASE_NAME, ".token");
export var AUTH_REFRESH_TOKEN_NANE = "".concat(COOKIE_BASE_NAME, ".refreshToken");
export var PERSONA_TOKEN_COOKIE = "".concat(COOKIE_BASE_NAME, ".persona");
export var PERSONA_TOKEN_HEADER = 'persona'; // MAGIC NUMBERS

export var THIRTY_DAYS_IN_SECONDS = 60 * 60 * 24 * 30; // ERRORS

export var ERROR_CODE_TOKEN_EXPIRED = 'token.expired';
export var ERROR_CODE_PERSONA_NOT_FOUND = 'persona.notFound';
export var ERROR_CODE_INVALID_CREDENTIALS = 'invalid.credentials'; // BROADCAST

export var AUTH_BROADCAST_CHANNEL = 'auth';
export var BROADCAST_SIGNOUT_MESSAGE = 'signOut'; // LOCALSTORAGE

export var LOCAL_STORAGE_CUSTOM_ENDPOINT_WHITELIST = [null];
export var LOCAL_STORAGE_TOKEN = "".concat(COOKIE_BASE_NAME, ":userHash");
export var PERSISTED_ANSWERS_LOCAL_STORAGE_KEY = "".concat(COOKIE_BASE_NAME, ".answers");
export var PERSISTED_AVAILABLE_SURVEYS_LOCAL_STORAGE_KEY = "".concat(COOKIE_BASE_NAME, ".availableSurveysList");
export var PERSISTED_LAST_SURVEY_LOCAL_STORAGE_KEY = "".concat(COOKIE_BASE_NAME, ".previousSurvey");
export var PERSISTED_CALLBACK_LOCAL_STORAGE_KEY = "".concat(COOKIE_BASE_NAME, ".callback"); // SOCIAL MEDIA

export var SOCIAL_URL_FACEBOOK = 'https://www.facebook.com/Global-Mindpool-102931532045537';
export var SOCIAL_URL_LINKEDIN = 'https://www.linkedin.com/company/global-mindpool';
export var SOCIAL_URL_TWITTER = 'https://twitter.com/GlobalMindpool';
export var SOCIAL_URL_INSTAGRAM = 'https://www.instagram.com/globalmindpool/';
export var SHARE_INSTAGRAM_URL = 'https://www.instagram.com/globalmindpool/';
export var SHARE_URL_SHORTHAND = 'globalmindpool.org';
export var SHARE_HASHTAGS = ['GlobalMindpool', 'MakeYourVoiceHeard', 'DontChooseExtinction'];
export var FACEBOOK_HASTAG = '#GlobalMindpool';
export var TWITTER_RELATED_ACCOUNTS = ['@UNDP', '@globalmindpool']; // SHARE BUTTON

export var SHARE_TITLE = 'I just made my voice heard! You can also make your voice heard on Global Mindpool. @globalmindpool @UNDP';
export var SHARE_SUBJECT = 'I just made my voice heard! You can also make your voice heard on Global Mindpool.';
export var SHARE_BODY = 'I just made my voice heard! You can also make your voice heard on Global Mindpool. globalmindpool.org #GlobalMindpool #MakeYourVoiceHeard #DontChooseExtinction @globalmindpool @UNDP';
export var FIRST_TIME_SHARE_MODAL = 'FirstTimeShareModal'; // SURVEYS

export var SURVEYS_SIGNUP_SLUG = 'signup';
export var SURVEYS_SIGNUP_URL = "/survey/".concat(SURVEYS_SIGNUP_SLUG);
export var SURVEYS_END_PAGE_URL = '/survey/end';
export var SURVEYS_SIGNUP_ENDPOINT = '/signup/answers'; // QUESTIONS

export var QUESTIONS_EMAIL_SLUG = 'email';
export var QUESTIONS_PASSWORD_SLUG = 'password';
export var QUESTIONS_PASSWORD_CONF_SLUG = 'conf_password';
export var QUESTIONS_SINGLE_CHOICE_TYPE_NAME = 'singleChoice';
export var QUESTIONS_MULTI_CHOICE_TYPE_NAME = 'multiChoice';
export var QUESTIONS_GLOBE_DISPLAY_NAME = 'globe';
export var QUESTIONS_TEXT_TYPE_NAME = 'text';
export var QUESTIONS_PASSWORD_DISPLAY_NAME = 'password'; // MEASURES

export var ONE_HOUR_IN_SECONDS = 3600;
export var ONE_DAY_IN_SECONDS = 86400;
export var MOBILE_MENU_SCREEN_WIDTH = '1240px';
export var CONTENT_MAX_WIDTH = '1392px'; // VALIDATION

export var MIN_PASSWORD_LENGTH = 6;
export var FORM_ERROR_TYPE_PASSWORD_MATCH = 'passwordMatch';
export var FORM_ERROR_MESSAGE_INVALID_EMAIL = 'Please enter a valid email address';