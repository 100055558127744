import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/web.dom-collections.for-each.js";
import StatusCodes from 'http-status-codes';
import { parseCookies } from 'nookies';
import { AUTH_REFRESH_TOKEN_NAME, AUTH_TOKEN_NAME, ERROR_CODE_TOKEN_EXPIRED } from '../../lib/constants';
import { fetchCSRFCookie } from '../csrf';
import { clearAuthCookies, setAuthCookies } from '../auth/authCookies';
export function addAuthInterceptor(api) {
  var ctx = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  var isRefreshing = false;
  var failedRequestQueue = [];
  var cookies = null;
  api.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    var _error$response;

    if ((error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === StatusCodes.UNAUTHORIZED) {
      var _error$response2, _error$response2$data;

      cookies = parseCookies(ctx);

      if ((error === null || error === void 0 ? void 0 : (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : (_error$response2$data = _error$response2.data) === null || _error$response2$data === void 0 ? void 0 : _error$response2$data.code) === ERROR_CODE_TOKEN_EXPIRED) {
        var refreshToken = cookies[AUTH_REFRESH_TOKEN_NAME];
        var token = cookies[AUTH_TOKEN_NAME];
        var originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true; // eslint-disable-next-line no-param-reassign

          api.defaults.headers.Authorization = "Bearer ".concat(refreshToken);
          fetchCSRFCookie().then(function () {
            api.post('/auth/refresh', {
              token: token
            }).then(function (response) {
              var _response$data = response === null || response === void 0 ? void 0 : response.data,
                  newToken = _response$data.token,
                  newRefreshToken = _response$data.refreshToken;

              setAuthCookies(newToken, newRefreshToken, ctx); // eslint-disable-next-line no-param-reassign

              api.defaults.headers.Authorization = "Bearer ".concat(newToken);
              failedRequestQueue.forEach(function (request) {
                return request.onSuccess(newToken);
              });
              failedRequestQueue = [];
            }).catch(function (err) {
              failedRequestQueue.forEach(function (request) {
                return request.onFailure(err);
              });
              failedRequestQueue = [];
              clearAuthCookies();
            }).finally(function () {
              isRefreshing = false;
            });
          });
        }

        return new Promise(function (resolve, reject) {
          failedRequestQueue.push({
            onSuccess: function onSuccess(successToken) {
              originalConfig.headers.Authorization = "Bearer ".concat(successToken);
              resolve(api(originalConfig));
            },
            onFailure: function onFailure(err) {
              reject(err);
            }
          });
        });
      }
    }

    return Promise.reject(error);
  });
}