import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { parseCookies, setCookie } from 'nookies';
import { COOKIES_DOMAIN, ONE_YEARS_IN_SECONDS, PERSONA_TOKEN_COOKIE } from '../../lib/constants';
import { isServer } from '../../lib/isServer';
import { createPersonaToken } from '../../lib/persona';
export function addPersonaInterceptor(api) {
  api.interceptors.request.use(function (config) {
    if (isServer()) {
      return _objectSpread({}, config);
    }

    var persona = null;
    var cookies = parseCookies(null, {
      domain: COOKIES_DOMAIN,
      path: '/',
      secure: true,
      sameSite: true
    });
    persona = cookies === null || cookies === void 0 ? void 0 : cookies[PERSONA_TOKEN_COOKIE];

    if (!persona) {
      persona = createPersonaToken();
      setCookie(null, PERSONA_TOKEN_COOKIE, persona, {
        domain: COOKIES_DOMAIN,
        maxAge: ONE_YEARS_IN_SECONDS,
        path: '/',
        secure: true,
        sameSite: true
      });
    }

    return _objectSpread(_objectSpread({}, config), {}, {
      headers: _objectSpread(_objectSpread({}, config === null || config === void 0 ? void 0 : config.headers), {}, {
        'persona-token': persona
      })
    });
  }, function (error) {
    return Promise.reject(error);
  });
  return api;
}