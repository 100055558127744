import { transparentize } from 'polished';
var defaultSpacing = 8;
var spacing = {
  1: "".concat(defaultSpacing, "px"),
  2: "".concat(2 * defaultSpacing, "px"),
  3: "".concat(3 * defaultSpacing, "px"),
  4: "".concat(4 * defaultSpacing, "px"),
  5: "".concat(5 * defaultSpacing, "px")
};
var general = {
  background: {
    color: '#fff'
  }
};
var colors = {
  base: {
    white: '#FFFFFF',
    black: '#000000'
  },
  grey: {
    lightest: '#F3F4F8',
    lighter: '#D1D5DA',
    light: '#9B9FA4',
    regular: '#4D5156',
    dark: '#11151A'
  },
  red: {
    regular: '#FF617B'
  },
  green: {
    lightest: '#F9FFFE',
    lighter: '#D9F4EE',
    light: '#58c12c',
    regular: '#26BE9C',
    dark: '#1C987D',
    darker: '#04384B'
  },
  blue: {
    lightest: '#b5d5f5',
    lighter: '#94c4f5',
    light: '#6babeb;',
    regular: '#4f95dd',
    dark: '#3288ce',
    darker: '#006eb5',
    darkest: '#1f5a95'
  },
  yellow: {
    regular: '#FFE17E'
  },
  undp: {
    green: '#58C12C'
  },
  primary: '#58c12c',
  secondary: '#EDEDEF',
  tertiary: '#FF617B',
  success: '#00966D',
  warning: '#FF7F11',
  danger: '#C30052',
  light: '#E5E5E5',
  dark: '#242432',
  backgrounds: {
    default: '#EFF0F7',
    success: '#F2FFFB',
    danger: '#FFF3F8'
  },
  gradients: {
    green: 'linear-gradient(to right bottom, #6DE354 0%, #58C12C 33.33%, #43852E 100%)',
    greenHover: 'linear-gradient(to right bottom, #59BA3F 0%, #4DA82A 33.33%, #3D7D27 100%);',
    grey: 'linear-gradient(to right bottom, #DEE1E4 5.42%, #C3C7CD 34.78%, #A2A6AB 76.8%), #FFFFFF',
    red: 'linear-gradient(to right bottom, #FFA8B7 5.42%, #FF617B 34.78%, #E2556C 76.8%), #FFFFFF',
    black: 'linear-gradient(to bottom, #000000 0%,  transparent 80%)'
  },
  text: {
    light: '#A0A3BD',
    medium: '#6E7191',
    dark: '#14142B'
  }
};
var type = {
  text: {
    family: 'proxima-nova, arial, sans-serif',
    size: '18px'
  },
  heading: {
    h1: {
      family: 'proxima-nova, arial, sans-serif',
      size: '40px',
      lineHeight: 1.6,
      margin: 0
    },
    h2: {
      family: 'proxima-nova, arial, sans-serif',
      size: '36px',
      lineHeight: 1.6,
      margin: 0
    },
    h3: {
      family: 'proxima-nova, arial, sans-serif',
      size: '32px',
      lineHeight: 1.6,
      margin: 0
    },
    h4: {
      family: 'proxima-nova, arial, sans-serif',
      size: '28px',
      lineHeight: 1.5,
      margin: 0
    },
    h5: {
      family: 'proxima-nova, arial, sans-serif',
      size: '24px',
      lineHeight: 1.5,
      margin: 0
    },
    h6: {
      family: 'proxima-nova, arial, sans-serif',
      size: '20px',
      lineHeight: 1.5,
      margin: 0
    }
  }
};
var border = {
  radius: {
    small: '5px',
    medium: '12px',
    large: '16px'
  }
};
var index = {
  page: 100,
  header: 110,
  content: 100,
  sidebar: 200,
  sidebarOverlay: 190,
  loader: 510,
  modal: 500
};
var forms = {
  labels: {
    fontFamily: 'proxima-nova, arial, sans-serif',
    fontSize: '15px',
    fontWeight: 'bold',
    color: colors.text.dark,
    marginBottom: spacing[0]
  },
  inputs: {
    selectButtons: {
      minHeight: '58px'
    },
    height: '48px',
    border: {
      color: colors.tertiary,
      style: 'solid',
      size: '1px'
    },
    highlight: {
      boxShadow: "box-shadow: 0 0 0 2px ".concat(transparentize(0.8, colors.primary)),
      border: {
        color: colors.primary
      }
    },
    radio: {
      label: {
        fontFamily: type.text.family,
        fontSize: type.text.size,
        fontWeight: 'normal',
        color: colors.text.dark
      }
    }
  },
  errors: {
    fontFamily: 'proxima-nova, arial, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    color: colors.danger,
    marginTop: spacing[1],
    input: {
      border: "1px solid ".concat(colors.danger),
      color: colors.danger
    }
  }
};
var buttons = {
  height: {
    small: '32px',
    regular: '40px',
    large: '48px'
  },
  font: {
    family: 'proxima-nova, arial, sans-serif',
    size: '16px',
    weight: 400
  }
};
var surveys = {
  common: {
    header: {
      height: '70px'
    }
  }
};
var site = {
  header: {
    height: {
      desktop: '120px',
      mobile: '80px'
    }
  }
};
var breakpoints = {
  mobile: '320px',
  tablet: '768px',
  default: '1200px',
  xs: '0',
  sm: '480px',
  md: '816px',
  lg: '1184px',
  xl: '1200px'
};
export var theme = {
  general: general,
  colors: colors,
  type: type,
  border: border,
  index: index,
  forms: forms,
  spacing: spacing,
  buttons: buttons,
  breakpoints: breakpoints,
  surveys: surveys,
  site: site
};