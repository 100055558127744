import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { ERROR_CODE_CSRF_INVALID } from '../../lib/constants';
import { fetchCSRFCookie } from '../csrf';
export function addCSRFInterceptor(api) {
  var isFetching = false;
  var failedCSRFRequestQueue = [];
  api.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    var _error$response, _error$response$data;

    if (true && (error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : (_error$response$data = _error$response.data) === null || _error$response$data === void 0 ? void 0 : _error$response$data.code) === ERROR_CODE_CSRF_INVALID) {
      var originalConfig = error.config;

      if (!isFetching) {
        isFetching = true;
        fetchCSRFCookie().then(function () {
          failedCSRFRequestQueue.forEach(function (request) {
            return request.onSuccess();
          });
          failedCSRFRequestQueue = [];
        }).catch(function (err) {
          failedCSRFRequestQueue.forEach(function (request) {
            return request.onFailure(err);
          });
          failedCSRFRequestQueue = [];
        }).finally(function () {
          isFetching = false;
        });
      }

      return new Promise(function (resolve, reject) {
        failedCSRFRequestQueue.push({
          onSuccess: function onSuccess() {
            resolve(api(originalConfig));
          },
          onFailure: function onFailure(err) {
            reject(err);
          }
        });
      });
    }

    return Promise.reject(error);
  });
}