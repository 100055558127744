import _defineProperty from "/vercel/path0/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import { toast } from 'react-toastify';
export function showMessage(_ref) {
  var _ref$title = _ref.title,
      title = _ref$title === void 0 ? null : _ref$title,
      message = _ref.message,
      _ref$type = _ref.type,
      type = _ref$type === void 0 ? 'success' : _ref$type,
      _ref$options = _ref.options,
      options = _ref$options === void 0 ? {
    autoClose: 5000,
    position: 'top-right',
    theme: 'colored',
    hideProgressBar: true
  } : _ref$options;
  var toastMessage = message ? "".concat(title || '').concat(title && message ? ' - ' : '').concat(message || '') : message || '';

  var optionDefaults = _objectSpread(_objectSpread({}, {
    autoClose: 5000,
    position: 'top-right',
    theme: 'colored',
    hideProgressBar: true
  }), options);

  switch (type) {
    case 'error':
      toast.error(toastMessage, optionDefaults);
      break;

    case 'warning':
      toast.warn(toastMessage, optionDefaults);
      break;

    case 'info':
      toast.info(toastMessage, optionDefaults);
      break;

    default:
      toast.success(toastMessage, optionDefaults);
      break;
  }
}